export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Український"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизація"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули пароль?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вперше тут?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити обліковий запис"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновлення паролю"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон або e-mail:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновлення паролю"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По батькові:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата народження:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайти"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувача знайдено"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період з"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період по"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметри/Коментар"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцій не знайдено"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума операцій"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надходження:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" шт. на суму"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витрати:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типи операцій"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виплати, оплата послуг"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішні перекази"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата рахунку"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перекази на свої рахунки"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення крипто чеків"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активація крипто чеків"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інше"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надходження/витрати"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надходження"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витрати"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= рівно"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ не рівно"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> більше"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ більше або рівно"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< менше"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ менше або рівно"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно завершено"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В обробці"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчора"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тиждень"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місяць"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рік"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія операцій"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення рахунку"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок не обрано"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмін на біржі"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення адреси"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перекази всередині свого гаманця"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цифровий паспорт"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Докладніше"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата реєстрації:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остання авторизація:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої активи"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунки"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивитися всі"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнити"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вивести"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунків немає"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль підтвердження"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для підтвердження операцій необхідно створити пароль підтвердження."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярні платежі"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новини"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згорнути"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активів немає"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вивести"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнити"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обміняти"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувачеві"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальний баланс"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показувати в"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нефіатний баланс"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіатний баланс"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстраційні відомості"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтверджено"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальнодоступно"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайти"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У групі"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи платежів"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежі"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума платежу"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок переказу"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти шаблон"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнюваний рахунок"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спосіб платежу"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти шаблон"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення рахунку"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквізити переказу"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірити переказ"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про перекази"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інструкція"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення адреси"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнювана адреса"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До списку завдань"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса переказу"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити операцію"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія операцій"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список рахунків"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невідома помилка"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список токенів"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок переказу:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протекція угоди"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін протекції:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["днів"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти як шаблон"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переказ іншому користувачеві"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код протекції — це код, який повинен ввести отримувач для завершення угоди. Захищений переказ отримувач побачить одразу ж у розділі \"Історія операцій\", але скористатися грошима зможе тільки після того, як введе код протекції в картці транзакції. Якщо термін дії платежу минув, то кошти повертаються відправнику."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса переказу"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звідки"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куди"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переказ на свої рахунки"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перекази всередині гаманця"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щось пішло не так"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишок"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова інформація"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активний"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введення"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виведення"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити нову адресу"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити новий рахунок"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити обрані"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої активи"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список рахунків"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва рахунку"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити рахунки?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити рахунок?"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активів не знайдено"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунків не знайдено"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменування адреси"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити адреси?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити адресу?"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіатні"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнити"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вивести"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обміняти"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приховати"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип адреси"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип рахунку"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити адресу"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити рахунок"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення нової адреси"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення нового рахунку"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безпека"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування антифішингу є додатковим механізмом захисту під час роботи з кабінетом."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задана вами вітальна фраза буде відображатися у верхній частині будь-якої сторінки кабінету."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правильна фраза покаже вам, що ви перебуваєте на правильному сайті, неправильна фраза буде сигналом: не здійснюючи операцій, необхідно терміново зв'язатися зі службою безпеки нашої системи."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути антифішинг:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рядок антифішингу:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифішинг"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміни набудуть чинності після наступної авторизації"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування доступу за IP-адресою не дозволить вам (або зловмиснику) працювати із системою з адреси, не прописаної у списку дозволених."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження доступу до кабінету за IP-адресами"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати адреси"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок адрес: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш IP: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список адрес"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З адреси"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По адрес"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреси відсутні"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заборонити"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть IP-адресу"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адресу введено неправильно"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вказано жодної IP-адреси або блоку адрес."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жодна IP-адреса не дозволена для авторизації. Ви не зможете отримати доступ до свого рахунку."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ за IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["з:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чарівне слово - це додаткове налаштування безпеки."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо чарівне слово ввімкнено, то щоразу при авторизації система проситиме вас ввести якісь символи (за їх порядковим номером) із чарівного слова."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чарівне слово:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запитувати чарівне слово:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При вході в кабінет:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При оплаті через мерчант:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При використанні картки:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чарівне слово"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразові ключі значно підвищують безпеку системи."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час входу в програму вас попросять не лише ввести логін і пароль, а й черговий одноразовий ключ. Активація відбувається по черзі. Останній ключ діє до моменту, поки ви не створите нову серію ключів."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовувати одноразові ключі"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список ключів"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразові ключі"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старий пароль:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження паролю:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новий пароль\" і \"Підтвердження паролю\" не збігаються."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна паролю"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рядок від 4 до 63 символів. Допустимі символи латинського алфавіту, цифри та символи"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старий пароль:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження паролю:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження паролю:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новий пароль\" і \"Підтвердження паролю\" не збігаються."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не повинен містити кирилицю"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення паролю підтвердження"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна паролю підтвердження"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На головну"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рядок від 4 до 65 символів. Допустимі символи латинського алфавіту, цифри та символи"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У разі, якщо ви забудете пароль для доступу до кабінету, ви зможете відновити його, відповівши на секретне запитання."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цьому розділі ви можете змінити секретні питання/відповідь."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретне питання"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше питання:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповідь:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретне питання"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Своє питання"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дати"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["баланс "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торгова пара:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунки:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип обміну:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмін за ринковою ціною - буде використано найвигідніший курс обміну на момент виконання заявки. Увага, сума до отримання може змінюватися і відрізнятися від розрахованої в залежності від заявок на момент обміну."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмін за фіксованою ціною відбувається за сумою і курсом, вказаними вами. Буде розміщена заявка. Якщо вказаний курс сильно відрізняється від ринкового, закриття заявки може зайняти багато часу."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс (прогноз):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс не визначено, "])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рекомендується встановити курс вручну"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використати все"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всього:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По ринку"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(швидкий обмін за кращою зустрічною пропозицією)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіксований курс"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(розмістити заявку за вказаним курсом)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає відповідного рахунку"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна за 24 години"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коливання за 24 години"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обсяг торгів за 24 години"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума не може бути нульовою!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обміняти"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб побачити резюме, виберіть торгову пару"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо обміняти однакові активи"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біржа"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ринок. Підсумок"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подібні пропозиції"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зустрічні пропозиції"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дати"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої заявки"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Останні угоди"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата та час"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["курс"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозицій немає"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угод немає"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявок немає"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повністю виконано"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробка не розпочата"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробка розпочата"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частково виконано"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повністю скасовано"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частково виконано та скасовано"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі заявки"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі заявки"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["курс"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявок немає"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальна сума дано"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальна сума дано"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальна сума отримано"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальна сума отримано"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість спроб транзакції"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано актив"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано актив"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дебет рахунку"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредит рахунку"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список субдистриб'юторів"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок не обрано"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період з"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період по"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчора"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тиждень"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місяць"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рік"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип дати не обрано"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершення"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кінець"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початок"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Яка частина є фіксованою, а яка змінною"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума дано фіксована"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума до отримання фіксована"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип заявки"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувач бажає обміняти швидко за кращими зустрічними пропозиціями"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмін за строго фіксованим курсом"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробка не розпочата"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробка розпочата"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частково виконано"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повністю виконано"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повністю скасовано"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частково виконано та скасовано"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано актив"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано актив"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип дати"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дати суму"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримати суму"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["торгів"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торг"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозицій немає"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозиції"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID продавця"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код пропозиції"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірений продавець"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тільки для перевірених користувачів"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо торгувати з собою"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрати актив"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місія"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список крипто-активів"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-активів немає"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емісія токена"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ринок"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первинний"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторинний"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Співставлений з іншим активом"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детальна інформація про криптоактив"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати дані про крипто-актив"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова емісія"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити криптоактив"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ні"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токени можна купити в криптоматі"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токени можна продати у криптоматі"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відображення токенів"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для конкретного користувача"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID користувача"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спалювання активу"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва – це повна назва крипто-активу, якою його бачитимуть усі: сам емітент, покупці, продавці, процесинг та ін."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорочена абревіатура довжиною від 3 до 10 символів. \nДопустимі латинські літери у верхньому регістрі, дефіс (тире) та цифри."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть категорію, яка найбільше підходить для вашого крипто-активу"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формати, що підтримуються: png, jpg, jpeg, svg. \nРозмір файлу не більше 1 Мб, 500*500 пікселів"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опишіть, чому цей крипто-актив випускається емітентом, в якій сфері він працює або працюватиме, яка його місія"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мета/місія/призначення:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емісія:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість за одиницю:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість десяткових знаків для суми. Наприклад, точність більшості фіатних валют, таких як долар США або євро, становить 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість одиниць випущеного крипто-активу. Вартість емісії буде доступна негайно на вашому рахунку після завершення операції створення крипто-активу."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявлена вартість за одиницю:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гарантія:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповідальна за зобов'язання особа:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рішення про емісію крипто-активу:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт операцій у блокчейн:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип блокчейну:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути операції з крипто-активом:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження/Санкції:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкажіть, громадянам або податковим резидентам яких країн зі списку заборонено використання крипто-активу:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші обмеження та умови:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса хостингу:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса whitepaper:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші контакти:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На попереднє"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити крипто-актив"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва та мета"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фінансові характеристики"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емітент"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт у блокчейн"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміти та обмеження"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не визначено"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіксована прив'язка до фіатних валют та інших активів"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емітент (Особа, що випускає крипто-актив)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент (Особа, що розподіляє крипто-актив)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправник"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одержувач"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я (включити спонсорство)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розподіл крипто-активу"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив означає цифрове представлення вартості або права, яке може передаватися та зберігатися електронно з використанням технології блокчейн. Крипто-активи НЕ включають фіатні валюти, цінні папери та інші фінансові інструменти та активи, що підлягають окремому державному регулюванню."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати криптоактив"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністрація"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина відмови в акцепті"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок оферента"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок оферента у валюті прив'язки"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент - представник емітента активу в угодах купівлі/продажу на первинному ринку. \nРахунок пасивний. \nМоже бути рахунком емісії активу"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок пасивний, у валюті прив'язаний. \nПовинен належати тому ж користувачу, що й попередній рахунок"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити покупку активу користувачами у банкоматах"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма купівлі для вторинного ринку"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угоди на вторинному ринку виконуються через шлюзи"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма продажу для вторинного ринку"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угоди на вторинному ринку виконуються через шлюзи"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити угоди з активом на біржі"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити угоди з активом через P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви є індивідуальним підприємцем або представником юридичної особи, вам необхідно завантажити файл рішення про випуск цифрового активу у форматі PDF. \nРішення повинне містити всі основні характеристики віртуального активу та бути підписане електронним підписом ІП або даної юридичної особи. \nПриклад оформлення рішення про випуск цифрового активу Ви можете знайти на сторінках нашого сайту у розділі \"Документи\""])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розміщення віртуального активу"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підставити рахунок емісії"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати із рахунків емітента"])},
        "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума до списання:"])},
        "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає рахунку"])},
        "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити рахунок"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віртуальні активи"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування сповіщень"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщати про операції"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизація (вхід до кабінету, мобільного додатку тощо)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщати при здійсненні \"Фінансових операцій за допомогою картки\""])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витрати (будь-яка оплата, переказ, оплата послуг тощо)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання коштів"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання внутрішнього повідомлення"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання рахунку до сплати"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна налаштувань безпеки"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження операцій кодом повідомлення"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження авторизації кодом повідомлення"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження при здійсненні \"Фінансових операцій за допомогою картки\""])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження зміни налаштувань безпеки кодом повідомлення"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження витрат кодом повідомлення"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміни не збережено, зберегти?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не зберігати"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата отримання"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обробки"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість платежів"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в тому числі успішних"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в тому числі з помилками"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масові платежі"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провайдер послуг"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-й параметр"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жодного платежу не знайдено"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете зробити одночасно кілька платежів. \nДля цього Вам потрібно заздалегідь створити файл із описом платежів у форматі \"XLS\", \"XLSX\", \"CSV\"."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналіз файлу"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про файл"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всього платежів: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на загальну суму: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл платежів"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дебет рахунку"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здійснити платежі"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити файл платежів"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте вміст файлу"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масові платежі та перекази"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервіс масових платежів дозволяє швидко переводити кошти іншим користувачам та здійснювати платежі провайдерам послуг."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У розділі"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити файл платежів"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ви можете завантажити файл у форматах Excel (XLS, XLSX, CSV) або XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список завдань"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дозволяє переглядати статуси раніше створених запитів на масові платежі."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете створити файл у редакторі таблиць Excel."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити приклад файлу"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Важливі зауваження:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат файлу - CSV, XLS або XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл має бути без заголовків."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб уникнути перетворення довгих номерів на експоненціальний формат (наприклад, \"5,46546E+15\") та видалення початкових нулів, ви можете вставити апостроф (') перед числом або встановити формат комірки як текст перед введенням даних."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для платежів постачальникам послуг"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список колонок у файлі:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Вказується зі списку операторів, наведених"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тут"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Для всіх операторів мобільного зв'язку можна вказувати код оператора 7000 - у цьому випадку оператор буде визначений автоматично за номером телефону.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сума вказується в тикері активу, з якого здійснюється переказ. Якщо такого тикера немає, до операції автоматично застосовується системний коефіцієнт конвертації."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до платежу"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заповнювати не обов'язково, можна залишити порожнім; довжина не більше 255 символів."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Вказується значення параметра, потрібного оператором, наприклад, номер телефону. Багато операторів обмежуються одним параметром платежу, але якщо кількість параметрів більше одного, вам необхідно вказувати їх усі в сусідніх від першого стовпцях."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметри платежу можна переглянути, перейшовши до оплати якогось оператора:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для масових переказів користувачам"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Вказується 0 або взагалі не вказується."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сума відображається в тикері рахунку, з якого переводиться актив. Якщо такого тикера немає, до операції автоматично застосовується коефіцієнт перерахунку системи."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до платежу"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заповнювати не обов'язково, можна залишити порожнім; довжина не більше 255 символів."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок одержувача"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Тут вказується номер рахунку користувача, якому необхідно виконати переказ (20 цифр)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для масової видачі позик кредитним агентом"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Цей рядок вказує, що платежі є позиками."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сума вказується у валюті кредитного продукту."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до кредиту"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заповнювати не обов'язково, можна залишити порожнім; довжина не більше 255 символів."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID позичальника"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Зверніть увагу, що у позичальника має бути цифровий паспорт Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код кредитного продукту"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Переглянути його можна у списку кредитних продуктів на ручному наданні позики."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Просто число періодів (які саме періоди використовуються, визначено у кредитному продукті, наприклад, це можуть бути дні, тижні, місяці тощо)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора (номер форми)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заповнення цього та наступних полів не обов'язкове, якщо їх не заповнювати, позичальник зможе пізніше самостійно здійснити виведення кредиту через свій особистий кабінет."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати у разі помилки"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Скасувати (1) наданий кредит, якщо транзакція виведення завершиться з помилкою, або залишити для подальшого самостійного виведення (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Вказується значення параметра, потрібного оператором, наприклад, номер телефону. Багато операторів обмежуються одним параметром платежу, але якщо кількість параметрів більше одного, вам необхідно вказувати їх усі в сусідніх від першого стовпцях."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Останнє повідомлення"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запитів немає"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пріоритет"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст повідомлення"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файли"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете завантажити один або декілька файлів. Максимальний розмір файлу - 10МБ."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Низький"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормальний"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Високий"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Технічна підтримка"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активні запити"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архів"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий запит"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробляється оператором"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит створено"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проводиться робота, чекайте відповіді оператора"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит закрито"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит закрито"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нових повідомлень немає"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нове повідомлення від оператора"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нових повідомлень немає"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити в архів"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі запити"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переказ"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виведення"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поля"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблонів немає"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримувач"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити шаблон №"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменувати шаблон"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменувати"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовувати"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про контрагента"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Рахунок / E-mail / Телефон"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Крипто-адреса / E-mail / Телефон"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальні відомості"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відомості про оплату"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольний підпис"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої магазини"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазинів не знайдено"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити код віджета"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити платіжне посилання"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити магазин"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити магазин"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструвати магазин"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим платежів"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструвати магазин"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєстрований в системі електронний магазин може приймати платежі від клієнтів через мерчант нашої системи."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як магазин реєструються електронні магазини, обмінні пункти та будь-які інші інтернет-ресурси, які бажають приймати платежі на свою адресу."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва або торгова марка"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адреса магазину"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Короткий опис магазину"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип сервісу"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин активний"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретний ключ"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити автовиплати"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправляти результати на скрипт"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса скрипта"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернення після успішного платежу"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після НЕуспішного платежу"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щонайменше 30 знаків."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовується для перевірки цілісності та достовірності переданих даних платіжною системою (при відправці магазином користувача на мерчант) та магазином (при отриманні результатів платежу)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До списку магазинів"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список інвойсів"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інвойсів не знайдено"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішній актив"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішній актив"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва EN"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синоніми"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країни"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо заповнено, оголошення буде доступно тільки користувачам з зазначених країн"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язати до активу"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одиниці вимірювання"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорочено"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова інформація"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквізити"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрямок переказу"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімум"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміт"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування (хв)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оголошення активно"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки за посиланням"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки верифікованим користувачам"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До попереднього"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розмістити"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрямок"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення оголошення"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свій зовнішній актив"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не прив'язувати"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоротити курс"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернути назад"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає рахунку в такому активі"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити рахунок"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкажіть курс"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс коректний"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоротити курс"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернути назад"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімум більше максимуму"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куди і як хочете отримати переказ. Наприклад: Переведіть в додатку банку за номером телефону +3809900011111 (одержувач Олександр Н.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["віддаю"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отримую"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активи"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запитувані реквізити"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Які реквізити повинен буде надіслати тейкер, щоб ви могли зробити йому переказ"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати актив"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати актив"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що хочете отримати: кошти у внутрішньому активі, наприклад, BTC або у зовнішньому, наприклад TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишіть коментар про особливості проведення цієї угоди, наприклад: \\\"Зовнішній актив передається тільки при особистій зустрічі\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид цінностей, до яких належить зовнішній актив, наприклад \\\"Готівкові гроші\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва активу для відображення на платформі"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив також може називатися словами зі списку"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Співставлення з існуючим активом"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наприклад: літри, штуки, години, коробки"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наприклад: л, шт, год, кор"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скільки може бути цифр після коми в значеннях активу"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви обрали створення ще не існуючого активу, будь ласка, заповніть його характеристики"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час, який надається сторонам для виконання умов"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішнім активом є актив, що існує в системі \\\"New Reality\\\". Зовнішній актив - це актив, що не існує в системі \\\"New Reality\\\"."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не прив'язаний"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаю"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримую"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати угоду"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити участь в угоді"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відмовитися від угоди"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити час очікування"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасування угоди"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус користувача в угоді не визначений. Щось пішло не так"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросити арбітраж"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Погодитися на арбітраж"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомити про виконання переказу"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити отримання переказу"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцініть угоду"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити оцінку"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угода №"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сьогодні"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["завтра"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати Арбітраж"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати арбітраж"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви запросили арбітраж"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проти Вас запросили арбітраж"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йде арбітраж (Ви – позивач)"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йде арбітраж (Ви - відповідач)"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після закінчення таймера згода на арбітраж відбудеться автоматично і ви вже не зможете додати відповіді на питання арбітражу"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддати"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримати"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавець"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаєте"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримуєте"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміти"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["угод"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угода"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угод немає"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозиції"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вигода"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код угоди"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаю"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримую"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрямок"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активних угод немає"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої угоди"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішній актив"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис активу"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оголошень немає"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої оголошення"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої оголошення"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нове оголошення"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрямок"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішній актив"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішній актив"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаю"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримую"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміти"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата публікації"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишок на рахунку"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит угоди"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозиція"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавець"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування дій сторін"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаєте"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримуєте"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросити угоду"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити рахунок"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок не обрано"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума не потрапляє в діапазон: "])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["хв"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаваний"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримуваний"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["актив"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["віддаю"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["внутрішній"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отримую"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішні активи"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активи не знайдено"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішні активи"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зовнішній"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрано:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішні"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішні"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросити арбітраж"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вчора"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішні активи"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язано до активу"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одиниці вимірювання"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата створення"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період з"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період по"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активів немає"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчора"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тиждень"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місяць"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рік"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язано до активу"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країни"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити зовнішній криптоактив"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оголошення"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквізити"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальна"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальна"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміт суми"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування (хв)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оголошення активне"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки за посиланням"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки верифікованим користувачам"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати зовнішній актив"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синоніми"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країни"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язати до активу"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одиниці вимірювання"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорочено"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початок"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати все"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомати"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натисніть для розвороту таблиці на повний екран"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субдилери"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити банкомат"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати криптомат"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основна інформація"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основне"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розклад роботи"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис місця встановлення"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормований"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ненормований"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим роботи"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понеділок"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вівторок"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середа"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвер"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["П'ятниця"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субота"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неділя"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обідня перерва"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтор пароля"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використати існуючі рахунки"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити нові рахунки"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основні відомості"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час роботи (довідкова інформація)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптомати не знайдені"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер банкомату"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["широта"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["довгота"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон підтримки"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID субдилера"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунки"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзитний рахунок"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення криптомату"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування криптомату"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заборонити"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список інкасаторів"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати інкасатора"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо перейти до інкасаційних залишків – немає даних"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список інкасаторів"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати інкасатора"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволено"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати банкомат"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути поточні інкасаційні залишки"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути вивантажені інкасаційні залишки"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пін-код"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо не вказувати субдилера, то банкомат буде прив'язаний до вас"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта і довгота - це географічні координати, які можна скопіювати, наприклад, з Google Maps, ввівши адресу банкомату"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місце встановлення"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для відображення таблиці має бути обраний хоча б один стовпець"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти до пристроїв банкомату"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронні чеки"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити чек"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створено"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок списання"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серія"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не активно"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чеків немає"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активувати"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірити"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронних чеків не знайдено"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення електронного чека"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок списання"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал кожного чека"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість чеків"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серія:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активації:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створено чеки"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити файл з чеками"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активація електронного чека"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок зарахування"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серія"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активації"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірити"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активувати"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упс, сталася помилка!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація банкоматів"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати інкасацію"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показувати архівні інкасації"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показувати скасовані інкасації"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає інкасацій"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявку на інкасацію подано"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати інкасацію"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити статус інкасації"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання інкасації"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основна інформація"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основне"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість карток по заявці"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купюроприймач"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасатори"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примітка для інкасаторів"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до інкасації"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження позачергово"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замінити касету купюроприймача"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до касети"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до карт"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилучити всі картки з касети Reject диспенсера карт"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилучити всі карти з туби"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки – обов'язкове поле, яке має бути заповнене"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал касети 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр у касеті 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума у ​​касеті 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал касети 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр у касеті 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до касети 1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал касети"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до касети"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування інкасації"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер касети"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер інкасації касети"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал касети"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажено"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактично"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до касети"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає даних для відображення"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація пристрою"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["діє"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["залишки рознесені"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject/Retract"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купюроприймач"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject картки"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поточні"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вивантажені"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасаційні залишки з банкомату"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація пристрою"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["діє"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["касета встановлена ​​в банкомат"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вилучено"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["залишки рознесені"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["залишки рознесені"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["касета порожня:"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["злипання:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова інформація:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по теперішній час"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір статусу"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявку на інкасацію подано"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявку на інкасацію скасовано"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація затверджена до виконання"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація виконана касиром (гроші завантажені у касети)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касети передані інкасаторам (гроші у дорозі)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасацію встановлено на банкоматі"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В результаті збою перевантажена іншою інкасацією"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація змінена іншою інкасацією на банкоматі (залишки в дорозі)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рознесено інкасаційні залишки (інкасація відправлена ​​до архіву)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати інформацію про інкасатора"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасування"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір рахунку оферента"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поля форми №"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає обов'язкового ідентифікатора"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви успішно авторизувалися, продовжите операції на банкоматі"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжіть операції на банкоматі"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати стовпці для відображення"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити звіт"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зразок"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аркуш"])}
    },
    "ATMDevices": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрої банкомату"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати пристрій"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип пристрою"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфігурація"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати касету"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип касети"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфігурація"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити пристрій"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити касету"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити пристрій?"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити касету?"])}
    },
    "ListRates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютні пари"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити валютну пару"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати валютну пару"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити валютну пару"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип оновлення"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютних пар не знайдено"])}
    },
    "RateItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити валютну пару"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування оновлення"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримувана валюта"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавати до курсу прибуток, %"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип оновлення курсу"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін життя курсу, секунд"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтервал оновлення курсу, секунд"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На яку дату курс актуальний"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аліас валюти, що віддається"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Псевдонім отриманої валюти"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібно обов'язково вибрати валюту і до того ж лише одну: віддається або отримувана"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума відправки * Курс = Сума отримання"])}
    },
    "SupportNew": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення запиту на підтримку"])}
    },
    "ShowInfoVA": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дані крипто-активу"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса емісії"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тікер"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволи"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мета/Місія/Призначення"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фінансові характеристики"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емісія"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особа, яка несе зобов'язання"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емітент"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість одиниці"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забезпеченість"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купівля у банкоматах"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продаж у банкоматах"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угоди з активом на біржі"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угоди з активом через P2P"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса хостингу"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса білої книги"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші контакти"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти"])}
    },
    "Doc": {
      "Merchant": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рядок до 255 символів"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ціле число"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 цифр"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 або 1"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дробове число, точність два знаки, роздільник точка"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ціле число"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рядок до 255 символів, необов'язковий"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ціле число, необов'язковий"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рядок 32 символи"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер транзакції магазину; \nмає бути унікальним для кожного платежу; \nпід час передачі рядка"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["унікальний номер буде згенеровано автоматично"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер магазину, наданий після реєстрації"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер рахунку магазину; \nцей рахунок також визначає, у якій валюті необхідно здійснити платіж (у більшості випадків користувач може здійснити платіж у будь-якій валюті, а кошти будуть автоматично сконвертовані у валюту одержувача)"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заборонити клієнту змінювати зазначену суму (1 – заборонити, 0 – дозволити)"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сума отримувача у валюті рахунку отримувача"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер форми, через яку здійснюється прийом оплати"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["коментар, що відображається платнику як мета платежу"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["додатковий необов'язковий параметр, який буде передано на скрипт результату платежу, а також на сторінки повернення"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адреса, куди клієнт повернеться після успішної оплати; \nякщо не вказано, то береться з налаштувань магазину"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адреса, куди клієнт повернеться після помилки;"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адреса, куди буде надіслано повідомлення про результати платежу; \nякщо не вказано, то береться з налаштувань магазину"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["термін життя рахунку на оплату (у хвилинах), за замовчуванням 60"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["підпис повідомлення з використанням секретного ключа магазину"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 або 1"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - у разі успіху, 0 - у противному випадку"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ціле число"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["код помилки (0 у разі відсутності помилки)"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рядок"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["текст помилки (або пояснення статусу платежу)"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 або 1"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ознака того, що остаточний статус платежу ще не відомий"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер транзакції магазину"])}
      }
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональний аккаунт"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бізнес аккаунт"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По батькові: (якщо є)"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі латинські букви, а також дефіс та апостроф."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі латинські букви, а також дефіс, пробіл та апостроф."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі латинські букви, а також дефіс, лапки, пробіли, цифри та апостроф."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі латинські букви (великими і малими літерами), цифри, символи _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторіть пароль"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паролі не збігаються"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дані користувача"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкажіть коректну адресу електронної пошти або номер телефону. Номер телефону вказується в міжнародному форматі, наприклад: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви успішно зареєстровані"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тепер ви можете"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["авторизуватися в особистому кабінеті"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш аккаунт буде перевірений нашим співробітником, про результати його активації ми повідомимо вам пізніше"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["відправлено листа з посиланням для підтвердження реєстрації"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На вашу адресу електронної пошти"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після першої авторизації вам буде запропоновано підтвердити номер телефону"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримувач:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувача не знайдено"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Докладніше"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір користувачів"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успіх"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["транзакція"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальні параметри"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна залишків на рахунках"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На кінець операції:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На початок операції:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткові параметри"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції в блокчейні"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наступна спроба:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповідь"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протекція угоди"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін протекції:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код протекції:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметри шаблону"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону:"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати операцію"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити операцію"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти шаблон"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер транзакції"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежу"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер платіжної форми"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прихід/Витрата"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна залишків адрес"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити зарахування"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернути гроші відправнику"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підозріла операція"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варіанти дій:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувача не знайдено"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть пароль підтвердження"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити операцію"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія операцій"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список рахунків"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успіх"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невідома помилка"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої активи"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про новий актив"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова емісія"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список віртуальних активів"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання на блокчейн"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введення:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть адресу"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Населений пункт"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країна"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регіон"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Район"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місто"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішньоміська територія"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Населений пункт"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вулиця"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додатковий елемент адреси"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уточнення"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будинок"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поштовий індекс"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит в технічну підтримку"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["від"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чат"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати повідомлення"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файли"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете завантажити один або декілька файлів. Максимальний розмір файлу - 10МБ."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст повідомлення"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення віджета для"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата в"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума платежу"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії при успіху"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії при неуспіху"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерація коду / посилання для оплати"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ операції магазину"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додатковий параметр"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішний URL"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилковий URL"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL повідомлення"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куди повернеться клієнт після успішної оплати"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куди клієнт повернеться після помилки"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куди буде відправлено повідомлення про результати оплати"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зафіксувати суму"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копіювати"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірити"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання на оплату"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документація"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін дії рахунку на оплату в хвилинах, за замовчуванням 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час дії посилання"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віджет"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо не фіксувати суму, то платник зможе запровадити її самостійно (підходить, наприклад, для благодійних платежів)"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приховати"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати магазин"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви це бачите, форму не було отримано (але ви не повинні це бачити)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова емісія"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок - джерело покриття"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок списання комісії"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова емісія"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний розмір емісії"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОКІН"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покриття"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахунок покриття"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язка:"])}
        },
        "Warning": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасування"])}
        },
        "BurnEmission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спалювання активу"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок для повернення"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума спалювання"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До списання"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інфо"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До зарахування"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо розрахувати суму"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати файл"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей формат файлу не підтримується. \nДопустимі формати:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невідомий формат"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Занадто великий розмір файлу, максимальний розмір:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть або перетягніть сюди файли для завантаження"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файли завантажено"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["файлів"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити статус"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корисні посилання"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші налаштування безпеки"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібно підтвердження"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизацію необхідно підтвердити одноразовим ключем"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизацію необхідно підтвердити чарівним словом"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрацію необхідно підтвердити кодом з SMS-повідомлення"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рядок від"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["символів"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недопустиме значення"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обов'язкове поле"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте поля"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натисніть , щоб вибрати стовпці, що відображаються."])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доброго ранку"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добрый день"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добрий вечір"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доброї ночі"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показано:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["всього"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На сторінці"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати"])}
      },
      "Header": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити платіж"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахувати комісію за повернення"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернути платіж"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хеш в блокчейні"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML-ризик"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До зарахування"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви згодні зі зміненими умовами, підтвердіть зарахування:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ці умови вам не підходять, ви можете повернути кошти на рахунок відправника за вирахуванням витрат на повернення:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на суму"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["має AML-ризик"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тому за її зарахування перерахована комісія"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["таким чином, сума до зарахування складає"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нових надходжень не виявлено"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежу"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернення"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])}
      }
    }
  }
}