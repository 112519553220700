<template>
    <div class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input" :class="{'mt-0': this.first}">
        <div 
            class="p-0 mt-0" 
            :class="{'col-sm-12': this.doublebus || this.is_request, 'col-sm-3': !this.doublebus && !this.is_request, 'mb-1': this.is_request,}">
            <label :for="id" class="col-form-label input-label pt-o pb-0">
                {{this.label}}
                <span class='require' v-if="this.require"> *</span>
            </label>
        </div>
        <div 
            class="p-0 input-wrapper mt-0" 
            :class="{
                'col-sm-12': this.doublebus || this.is_request, 
                'col-sm-9': !this.doublebus && !this.is_request
            }"
        > 
            <SelectSimple
                :dataValue.sync='this.dataValue' 
                :id='this.id'
                :hotUpdate="this.hotUpdate" 
                :keyt="this.keyt"
                :keyt_thin="this.keyt_thin"
                :keywords.sync='this.keywords'
                :invalid='this.invalid && !this.disable'
                :disable="this.disable"
                :defaultValue="this.defaultValue"
                :isSearch="this.isSearch"
                :searchType="this.searchType"
                :isFilterSelect="this.isFilterSelect"
                @blurInput="this.blure"  
                @updateParentState="this.setValue"
            />
            <Tooltip v-if="this.hint" :id="id + '_hint'" :content='this.hint'/>
            <div
                v-else-if="this.doublebus"
                class="mw34"
                style="margin: 0 5px;"
            ></div>
            <div v-else-if="this.unvisible" class="mw34"></div>
            <Tooltip
                v-if="this.verification"
                :id="id + '_check'"
                :custom="'item-i check'"
                :content="$t('views.InfoReg.3')"
            />
            <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
            <Tooltip v-if="this.unvisible" :content="$t('views.InfoReg.4')" :id="id + '_av'" :htmlContent="true">
                <SwitchSimple
                    :id="id + '_switch_id'"
                    :dataValue='this.unvisibleValue'
                    :disabled="this.is_public == '2' ? true : false"
                    :type="'sm'"
                    class="ml-1"
                    @updateParentState="this.setUnvisibleValue"
                />
            </Tooltip>
        </div> 
    </div>
</template>

<script>
import SelectSimple from '@/components/service/SelectSimple';
import SwitchSimple from '@/components/service/SwitchSimple';
import Tooltip from '@/components/service/Tooltip';

export default {
    components: {
        Tooltip,
        SelectSimple,
        SwitchSimple,
    },
    data() {
        return {
            unvisibleValue: this.unvisibleDataValue ? this.unvisibleDataValue : '',
        }
    },
    props: {
        id: [String, Boolean],
        keywords: [Array],
        dataValue: [String, Boolean],
        require: [Boolean],
        hint: [String, Boolean],
        invalid: [Boolean],
        label: [String],
        doublebus: [Boolean],
        verification: [String, Boolean],
        keyt: {
            default: false,
            type: [Boolean],
        },
        keyt_thin: {
            default: false,
            type: [Boolean],
        },
        first: {
            type: Boolean,
            default: false,
        },
        disable: {
            default: false,
            type: [Boolean]
        },
        defaultValue: {
            default: '',
            type: [String],
        },
        isSearch: {
            default: false,
            type: [Boolean]
        },
        unvisible: [Boolean],
        unvisibleDataValue: [String, Boolean], 
        vMark: [Boolean],
        is_public: [Boolean, String],
        is_request: [Boolean],
        searchType: {
            default: 'value',
            type: [String],
            Required: false
        },
        isFilterSelect: {
            default: false,
            type: [Boolean],
        },
        hotUpdate: {
            default: false,
            type: [Boolean],
        },
    },
    methods: {
        setValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                value: arg.value
            })
        },
        blure(arg) {
            this.$emit('blurInput', {
                id: arg.id,
                value: arg.value
            })
        },
        setUnvisibleValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id.replace(/_switch_id/, ''),
                unvisible: true,
                value: arg.value
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.input {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }

}
.require {
    color: red;
}


</style>