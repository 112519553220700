export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant :"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe :"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première visite ?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération de mot de passe"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone ou e-mail :"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération de mot de passe"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille :"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom :"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronyme :"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance :"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer au bureau"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur trouvé"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période du"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période au"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres/Commentaire"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune opération trouvée"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant des opérations"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes :"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pièces pour un montant de"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses :"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types d'opérations"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements, paiement de services"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts internes"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement de facture"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts entre vos comptes"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de crypto-chèques"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation de crypto-chèques"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes/Dépenses"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= égal"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ différent"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> supérieur"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ supérieur ou égal"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< inférieur"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ inférieur ou égal"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé avec succès"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des opérations"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement de compte"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non sélectionné"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange sur le marché"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement d'adresse"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts sur compte propre"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport numérique"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant utilisateur :"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'inscription :"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière connexion :"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes actifs"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe de confirmation"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour confirmer les transactions, vous devez créer un mot de passe de confirmation."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements populaires"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équilibre"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'utilisateur"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan global"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher dans"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde non fiduciaire"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan Fiat"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations d'enregistrement"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmé"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de paiement"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le groupe"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes de paiements"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de transfert"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte à recharger"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de paiement"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement de compte"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du transfert"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier le transfert"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur les transferts"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement d'adresse"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse à recharger"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la liste des tâches"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de transfert"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter l'opération"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des opérations"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des comptes"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inconnue"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des jetons"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de transfert :"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant :"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire :"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection de la transaction"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code :"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de protection :"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer comme modèle"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert à un autre utilisateur"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de protection est le code que le destinataire doit entrer pour terminer la transaction. Le destinataire verra immédiatement le transfert protégé dans la section \"Historique des opérations\", mais ne pourra utiliser les fonds qu'après avoir saisi le code de protection dans la carte de transaction. Si la période de validité du paiement expire, les fonds sont retournés à l'expéditeur."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de transfert"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert entre vos comptes"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts sur compte propre"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations supplémentaires"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortie"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle adresse"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau compte"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer sélectionnés"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes actifs"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des comptes"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du compte"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer les comptes ?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le compte"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif trouvé"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte trouvé"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer l'adresse"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer les adresses ?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer l'adresse"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non fiduciaire"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décret"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actes"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'adresse"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de compte"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une adresse"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'une nouvelle adresse"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un nouveau compte"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramétrage antiphishing est un mécanisme de protection supplémentaire lors de l'utilisation du compte."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La phrase de bienvenue que vous avez définie sera affichée en haut de chaque page du compte."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La phrase correcte vous indique que vous êtes sur le bon site, une phrase incorrecte signalera : n'effectuez aucune opération et contactez immédiatement le service de sécurité de notre système."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer antiphishing :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrase antiphishing :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiphishing"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications prendront effet après la prochaine connexion"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramétrage d'accès par adresse IP ne vous permettra pas (ou à un intrus) de travailler avec le système à partir d'une adresse qui n'est pas dans la liste des adresses autorisées."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction d'accès au compte par adresse IP"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des adresses"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse : "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloc d'adresses : "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à :"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre IP : "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des adresses"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De l'adresse"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'adresse"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez l'adresse IP"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP incorrecte"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse IP ou bloc d'adresses spécifié."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse IP autorisée pour l'autorisation. Vous ne pourrez pas accéder à votre compte."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès par IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de :"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot magique est un paramètre de sécurité supplémentaire."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le mot magique est activé, le système vous demandera, à chaque connexion, d'entrer certains caractères (selon leur ordre) du mot magique."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot magique :"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander le mot magique :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'accès au compte :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors du paiement par le marchand :"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'utilisation de la carte :"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot magique"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clés à usage unique augmentent considérablement la sécurité du système."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la connexion au programme, on vous demandera non seulement de saisir l'identifiant et le mot de passe, mais aussi la clé à usage unique suivante. L'activation est effectuée successivement. La dernière clé est valide jusqu'à ce que vous créiez une nouvelle série de clés."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser des clés à usage unique"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des clés"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés à usage unique"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nouveau mot de passe\" et \"Confirmation du mot de passe\" ne correspondent pas."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de 4 à 63 caractères. Les caractères autorisés sont les lettres de l'alphabet latin, les chiffres et les symboles"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nouveau mot de passe\" et \"Confirmation du mot de passe\" ne correspondent pas."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne doit pas contenir de caractères cyrilliques"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe de confirmation"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe de confirmation"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la page principale"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de 4 à 65 caractères. Les caractères autorisés sont les lettres de l'alphabet latin, les chiffres et les symboles"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous oubliez le mot de passe pour accéder au compte, vous pourrez le récupérer en répondant à la question secrète."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette section, vous pouvez changer la question/réponse secrète."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question secrète"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre question :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question secrète"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question personnelle"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je remets"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solde "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paire de trading:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'échange :"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert au marché - l'échange se fera au taux le plus avantageux au moment de l'exécution de l'ordre. Attention, le montant à recevoir peut changer et différer du montant calculé en fonction des ordres au moment de l'échange."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert au prix fixe se fait conformément au montant et au taux que vous avez spécifiés. Une commande sera créée. Si le taux spécifié diffère fortement du taux du marché, la fermeture de la commande peut prendre beaucoup de temps."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux (prévision) :"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux non défini,"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il est recommandé de définir le taux soi-même"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant :"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser tout"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission :"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission :"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total :"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon le marché"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(échange rapide à la meilleure offre)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux fixe"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(placer une commande au taux spécifié)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte approprié"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement sur 24 heures"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluctuations sur 24 heures"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume des transactions sur 24 heures"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant ne peut pas être nul !"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échanger"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour voir les données récapitulatives, sélectionnez la paire de trading"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit d'échanger des actifs identiques"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bourse"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché. Résumé"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres similaires"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres contraires"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils donnent"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils reçoivent"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières transactions"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donné"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je remets"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune commande"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement exécuté"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement non démarré"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement démarré"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement annulé"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté et annulé"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les commandes"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les commandes"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je remets"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune commande"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant minimum à donner"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant maximum à donner"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant minimum à recevoir"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant maximum à recevoir"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de tentatives de transaction"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise de paiement"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise à recevoir"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte débiteur"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte créditeur"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des sous-marchands"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non sélectionné"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période du"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période au"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de date non sélectionné"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle partie est fixe et quelle partie est variable"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant à transférer est fixe"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à recevoir est fixé"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de commande"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur souhaite échanger rapidement à la meilleure offre"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange à un taux fixe"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement non démarré"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement démarré"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement exécuté"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement annulé"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté et annulé"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif cédé"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif reçu"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de date"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à transférer"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à recevoir"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres P2P"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du vendeur"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'offre"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur vérifié"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservé aux utilisateurs vérifiés"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de faire une transaction avec soi-même"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner l'actif"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des crypto-actifs"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission de jeton"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primaire"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondaire"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associé à un autre actif"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations détaillées sur l'actif cryptographique"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les données sur un actif cryptographique"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème supplémentaire"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'actif cryptographique"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les jetons peuvent être achetés dans un cryptomat"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les jetons peuvent être vendus dans un cryptomat"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage des jetons"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour un utilisateur spécifique"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID utilisateur"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brûlage d'actifs"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom est le nom complet du crypto-actif, tel qu’il sera vu par tous : l’émetteur lui-même, les acheteurs, les vendeurs, le transformateur, etc."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une abréviation de 3 à 10 caractères. \nLes lettres latines majuscules, les tirets (tirets) et les chiffres sont acceptés."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la catégorie à laquelle votre crypto-actif correspond le mieux"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formats pris en charge : png, jpg, jpeg, svg. \nTaille du fichier ne dépassant pas 1 Mo, 500*500 pixels"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrire la raison pour laquelle ce crypto-actif est émis par l'émetteur, dans quel domaine il travaille ou travaillera, quelle est sa mission."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo :"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif / mission / but :"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision :"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission :"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur unitaire :"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de décimales pour le montant. Par exemple, la précision de la plupart des monnaies fiduciaires telles que le dollar américain ou l'euro est de 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre d'unités de l'actif cryptographique en cours d'émission. Le montant de l'émission sera immédiatement disponible sur votre compte une fois la transaction de création de l'actif cryptographique terminée."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur unitaire déclarée :"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie :"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte :"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne responsable :"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision d'émettre un crypto-actif:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les opérations vers la blockchain :"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de blockchain :"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable crypto-asset transactions:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions/Sanctions :"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifiez quels citoyens ou résidents fiscaux de quels pays de la liste ci-dessus sont interdits d'utiliser des crypto-actifs dans leur chiffre d'affaires:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres restrictions et conditions :"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse d'hébergement:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du livre blanc :"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email :"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram :"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter :"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook :"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres contacts :"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un crypto-actif"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et objectif"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques financières"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émetteur"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export vers la blockchain"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites et restrictions"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non défini"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixation au pair avec des devises fiduciaires et d'autres actifs"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émetteur (Personne émettant un crypto-actif)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrant (Personne distribuant un crypto-actif)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expéditeur"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moi (activer le parrainage)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement de crypto-actifs"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-actif désigne une représentation numérique d'une valeur ou d'un droit qui peut être transférée et stockée électroniquement à l'aide de la technologie blockchain. Les crypto-actifs ne comprennent PAS les monnaies fiduciaires, les titres et autres instruments financiers et actifs soumis à une réglementation gouvernementale distincte."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'actif cryptographique"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif du refus d'accepter"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de l'offrant"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de l'offrant dans la devise contraignante"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Offrant est un représentant de l'émetteur d'un actif dans les opérations d'achat/vente sur le marché primaire. \nLe compte est passif. \nPeut être un compte d'émission d'actifs"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte est passif, lié en devise. \nDoit appartenir au même utilisateur que le compte précédent"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les utilisateurs à acheter un actif aux guichets automatiques"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d'achat après-vente"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions sur le marché secondaire sont effectuées via des passerelles"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire de vente après-vente"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions sur le marché secondaire sont effectuées via des passerelles"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les transactions avec un actif en bourse"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les transactions d'actifs via P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous êtes un entrepreneur individuel ou un représentant d'une personne morale, vous devez télécharger le dossier de décision de libération d'un actif numérique au format PDF. \nLa solution doit contenir toutes les principales caractéristiques de l'actif virtuel et être signée avec une signature électronique de l'entrepreneur individuel ou de cette personne morale. \nUn exemple de décision d'émission d'un actif numérique est disponible sur les pages de notre site Internet dans la rubrique « Documents »"])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placer un actif virtuel"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte d'émission de remplacement"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner parmi les comptes émetteurs"])},
        "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à amortir :"])},
        "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de compte"])},
        "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs virtuels"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de notification"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier des opérations"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation (connexion au compte personnel, application mobile, etc.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier en cas d'événement « Réalisation d'opérations financières avec la carte »"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses (tout paiement, transfert, paiement de services, etc.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée de fonds"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réception d'un message via le courrier interne"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réception d'une facture de paiement"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification des paramètres de sécurité"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer les opérations avec le code du message"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'autorisation avec le code du message"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer lors de la « Réalisation d'opérations financières avec la carte »"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la modification des paramètres de sécurité avec le code du message"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer les dépenses avec le code du message"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications non enregistrées, enregistrer ?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas enregistrer"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de réception"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de traitement"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de paiements"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y compris réussis"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y compris échoués"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements de masse"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur de services"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er paramètre"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement trouvé"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez effectuer plusieurs paiements en même temps. \nPour ce faire, vous devez d'abord créer un fichier avec une description des paiements au format \"XLS\", \"XLSX\", \"CSV\"."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse du fichier"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le fichier"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de paiements : "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total : "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier de paiement"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de débit"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer les paiements"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement du fichier de paiement"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez le contenu du fichier"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements et transferts de masse"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service de paiements de masse permet de transférer rapidement des fonds à d'autres utilisateurs et d'effectuer des opérations de paiement aux fournisseurs de services."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la section"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le fichier de paiement"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous pouvez télécharger un fichier au format Excel (XLS, XLSX, CSV) ou XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des tâches"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous permet de voir les statuts des demandes de paiements de masse créées précédemment."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez créer un fichier dans un éditeur de tableur comme Excel."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger un fichier exemple"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques importantes :"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format de fichier - CSV, XLS ou XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier ne doit pas contenir de titres."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour que les grands nombres ne se transforment pas en format exponentiel (par exemple, \"5,46546E+15\") et que les zéros initiaux ne soient pas coupés, vous pouvez ajouter un apostrophe (') avant le nombre, ou définir le format de la cellule comme texte avant de saisir les données."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format des colonnes pour les paiements aux fournisseurs de services"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des colonnes dans le fichier :"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'opérateur"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Spécifiez-le à partir de la liste des opérateurs"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Pour tous les opérateurs mobiles, vous pouvez spécifier le code opérateur 7000 - dans ce cas, l'opérateur sera automatiquement déterminé par le numéro de téléphone.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le montant est indiqué dans le ticker de l'actif à partir duquel le transfert est effectué. S'il n'y a pas de tel ticker, le taux de conversion du système est automatiquement appliqué à l'opération."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire de paiement"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optionnel, peut être laissé vide ; longueur maximale de 255 caractères."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Saisissez la valeur du paramètre requis par l'opérateur, par exemple le numéro de téléphone. De nombreux opérateurs se contentent d'un seul paramètre de paiement, mais si le nombre de paramètres est supérieur à un, vous devez les spécifier tous dans les colonnes adjacentes à la première."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez consulter les paramètres de paiement en accédant au paiement d'un opérateur spécifique :"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format des colonnes pour les transferts de masse aux utilisateurs"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'opérateur"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Spécifiez 0 ou laissez-le vide."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le montant est affiché dans le ticker du compte à partir duquel l'actif est transféré. S'il n'y a pas de tel ticker, le taux de conversion du système est automatiquement appliqué à l'opération."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire de paiement"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optionnel, peut être laissé vide ; longueur maximale de 255 caractères."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte bénéficiaire"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Saisissez ici le numéro de compte de l'utilisateur auquel le transfert doit être effectué (20 chiffres)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format des colonnes pour la distribution de prêts par un agent de crédit"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Cette ligne indique que les paiements sont des prêts."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le montant est spécifié dans la devise du produit de crédit."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur le crédit"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optionnel, peut être laissé vide ; longueur maximale de 255 caractères."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l'emprunteur"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Veuillez noter que l'emprunteur doit avoir un passeport numérique Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code du produit de crédit"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vous pouvez le voir dans la liste des produits de crédit lors de la distribution manuelle du prêt."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Simplement le nombre de périodes (les périodes utilisées sont définies dans le produit de crédit, par exemple, elles peuvent être des jours, des semaines, des mois, etc.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'opérateur (numéro du formulaire)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le remplissage de ce champ et des suivants est optionnel. Si vous ne les remplissez pas, l'emprunteur pourra retirer le prêt plus tard de manière autonome via son compte personnel."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler en cas d'erreur"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Annuler (1) le prêt accordé si la transaction de retrait échoue, ou le laisser pour un retrait autonome ultérieur (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Saisissez la valeur du paramètre requis par l'opérateur, par exemple le numéro de téléphone. De nombreux opérateurs se contentent d'un seul paramètre de paiement, mais si le nombre de paramètres est supérieur à un, vous devez les spécifier tous dans les colonnes adjacentes à la première."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier message"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune demande"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte du message"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez télécharger un ou plusieurs fichiers. Taille maximale du fichier - 10 Mo."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basse"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normale"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support technique"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes actives"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle demande"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement par l'opérateur"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande créée"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement, attendez la réponse de l'opérateur"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande fermée"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande fermée"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nouveau message"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau message de l'opérateur"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nouveau message"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à l'archive"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les demandes"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de modèle de paiement"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaire"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le modèle n°"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer le modèle"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur les contreparties"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Compte / E-mail / Téléphone"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Adresse crypto / E-mail / Téléphone"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature de contrôle"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes magasins"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun magasin trouvé"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un code de widget"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un lien de paiement"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le magasin"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le magasin?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le magasin"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le magasin"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un magasin électronique enregistré dans le système permet d'accepter des paiements des clients via le système de marchand."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les magasins électroniques, les bureaux de change et toute autre ressource internet souhaitant accepter des paiements en leur faveur sont enregistrés en tant que magasins."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom ou marque"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL du magasin"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description courte du magasin"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de service"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin est actif"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé secrète"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les paiements automatiques"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les résultats au script"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du script"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour après un paiement réussi"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après un paiement échoué"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 30 caractères."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé pour vérifier l'intégrité et l'authenticité des données transmises par le système de paiement (lorsque le magasin envoie l'utilisateur au marchand) et par le magasin (lors de la réception des résultats du paiement)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la liste des magasins"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des factures"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune facture trouvée"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif intérieur"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif externe"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom RU"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonymes"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si rempli, l'annonce ne sera visible que pour les utilisateurs des pays spécifiés"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rattacher à un actif"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abréviation"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction de transfert"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente (min)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annonce est active"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement par lien"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement pour les utilisateurs vérifiés"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placer"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction exchange"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une annonce"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propre actif externe"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas attacher"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le taux"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de compte dans un tel actif"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifier le taux"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le taux est correct"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le taux"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum supérieur au maximum"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où et comment souhaitez-vous recevoir le transfert. Par exemple : Effectuez le transfert dans l'application bancaire au numéro de téléphone +499990001111 (bénéficiaire Alexandre N.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je donne"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées demandées"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles coordonnées le preneur doit envoyer pour que vous puissiez effectuer le transfert"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'actif"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'actif"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que voulez-vous obtenir : des fonds en actif interne, par exemple, BTC, ou en actif externe, par exemple, TOKEN GOLD ?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez un commentaire sur les particularités de cette transaction, par exemple : \\\"L'actif externe est transféré uniquement lors d'une rencontre en personne\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de valeur à laquelle appartient l'actif externe, par exemple, \\\"Argent liquide\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'actif à afficher sur la plateforme"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'actif peut également être appelé par des mots de la liste"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance avec un actif existant"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par exemple : litres, pièces, heures, boîtes"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par exemple : l, p, h, b"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de chiffres après la virgule dans les valeurs de l'actif"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez choisi de créer un actif qui n'existe pas encore, veuillez remplir ses caractéristiques"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps accordé aux parties pour remplir les conditions"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un actif interne est un actif qui existe dans le système \\\"New Reality\\\". Un actif externe est un actif qui n'existe pas dans le système \\\"New Reality\\\"."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non comparé"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je transmets"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la transaction"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la participation à la transaction"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser la transaction"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prolonger le temps d'attente"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation de la transaction"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de l'utilisateur dans la transaction non défini. Quelque chose a mal tourné"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un arbitrage"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter l'arbitrage"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier du transfert effectué"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la réception du transfert"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer la transaction"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer l'évaluation"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction n°"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aujourd'hui"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demain"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'arbitrage"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'arbitrage"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez demandé un arbitrage"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un arbitrage a été demandé contre vous"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'arbitrage est en cours (Vous êtes le demandeur)"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'arbitrage est en cours (Vous êtes le défendeur)"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois le délai écoulé, le consentement à l'arbitrage se fera automatiquement et vous ne pourrez plus ajouter vos réponses aux questions d'arbitrage."])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous donnez"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevez"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avantage"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de transaction"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je transmets"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction active"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes transactions"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif interne"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de l'actif"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune annonce"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes annonces"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes annonces"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle annonce"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif externe"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif interne"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçois"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de publication"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde du compte"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de transaction"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente des actions des parties"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous donnez"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevez"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander une transaction"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non sélectionné"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant n’entre pas dans la fourchette :"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cédé"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actif"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je transmets"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interne"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je reçois"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs internes"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs non trouvés"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs externes"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externe"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné :"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internes"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un arbitrage"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs externes"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié à l'actif"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités de mesure"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période du"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période au"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié à l'actif"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'actif cryptographique externe"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de montant"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente (min)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce active"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement par lien"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement pour les utilisateurs vérifiés"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'actif externe"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonymes"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier à l'actif"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités de mesure"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abréviation"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeurs automatiques de billets"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour agrandir le tableau en plein écran"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-concessionnaires"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un guichet automatique"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le cryptomate"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations principales"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires d'ouverture"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du site d'installation"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalisé"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non normalisé"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de fonctionnement"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause déjeuner"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser les comptes existants"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer de nouveaux comptes"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de base"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures d'ouverture (informations de référence)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune machine cryptographique trouvée"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de guichet automatique"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitude"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitude"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne d'assistance"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du sous-revendeur"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de transit"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un cryptomat"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer un cryptomat"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interdire"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permettre"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des collectionneurs"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un collectionneur"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accéder aux soldes de collecte - aucune donnée"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des collectionneurs"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un collectionneur"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisé"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le guichet automatique"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les soldes de collecte actuels"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les soldes de collecte déchargés"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code PIN"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne précisez pas de sous-revendeur, le guichet automatique vous sera lié"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La latitude et la longitude sont des coordonnées géographiques qui peuvent être copiées, par exemple, depuis Google Maps en saisissant l'adresse du guichet automatique."])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu d'installation"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une colonne doit être sélectionnée pour afficher le tableau"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez aux appareils ATM"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chèques électroniques"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un chèque"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de radiation"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un commentaire"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activement"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas actif"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de chèques"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun reçu électronique trouvé"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un reçu électronique"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de radiation"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dénomination de chaque chèque"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un commentaire"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de chèques"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dos"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'activation :"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chèques créés"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le fichier avec les reçus"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation d'un chèque électronique"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de dépôt"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'activation"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, il y a eu une erreur !"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte aux guichets automatiques"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une collection"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actes"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas afficher les collections d'archives"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas afficher les collections annulées"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de point"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune collecte"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de collecte soumise"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la collection"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le statut de collecte"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une collection"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de base"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les bases"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeur"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de point"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de cartes par demande"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteur de billets"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collecteurs de fonds"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque pour les collectionneurs"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la collecte"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement dans le désordre"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer la cassette accepteur de billets"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la cassette"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur les cartes"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirez toutes les cartes de la cassette du distributeur de cartes de rejet."])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirez toutes les cartes du tube"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de point est un champ obligatoire qui doit être rempli"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement des cassettes 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures dans la cassette 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité dans la cassette 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement des cassettes 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures dans la cassette 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la cassette 1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement des cassettes"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la cassette"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de la collection"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de cassette"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de collection de cassettes"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement des cassettes"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargé"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En fait"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la cassette"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée à afficher"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte d'appareils"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valide"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les restes sont emportés"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeur"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter/Rétracter"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteur de billets"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter les cartes"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actuel"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déchargé"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldes de collecte aux guichets automatiques"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte d'appareils"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valide"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassette installée dans un guichet automatique"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saisi"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les restes sont emportés"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les restes sont emportés"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassette vide :"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adhésion:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations Complémentaires:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à ce jour"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le statut"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La demande de collecte a été déposée"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de recouvrement annulée"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection approuvée pour exécution"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'encaissement est effectué par le caissier (l'argent est chargé dans des cassettes)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassettes remises aux collectionneurs (argent en route)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte d'espèces installée sur le guichet automatique"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite à une panne, surchargé avec une autre collection"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collecte a été remplacée par une autre collecte à un distributeur automatique (reste en route)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les soldes de collecte sont comptabilisés (la collecte est envoyée aux archives)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actes"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les informations sur le collecteur"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection du compte offrant"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs du formulaire N°"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID requis manquant"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes connecté avec succès, continuez vos opérations au guichet automatique"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer les transactions aux guichets automatiques"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les colonnes à afficher"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un rapport"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feuille"])}
    },
    "ATMDevices": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareils ATM"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un appareil"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'appareil"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une cassette"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de cassette"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'appareil"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer la cassette"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'appareil ?"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la cassette ?"])}
    },
    "ListRates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paires de devises"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une paire de devises"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la paire de devises"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer une paire de devises"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actes"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de mise à jour"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune paire de devises trouvée"])}
    },
    "RateItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une paire de devises"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer une mise à jour"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise reçue"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter du profit au cours, %"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de mise à jour du cours"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de vie du cours, secondes"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalle de mise à jour du cours, secondes"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour quelle date le cours est-il valable ?"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​​​de la devise donnée"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​​​de la devise reçue"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il faut choisir une monnaie et, de plus, une seule : donnée ou reçue"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant d'envoi * Tarif = Montant de réception"])}
    },
    "SupportNew": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une demande d'assistance"])}
    },
    "ShowInfoVA": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données sur les actifs cryptographiques"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du problème"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléscripteur"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif/Mission/Objectif"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques financières"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligataire"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émetteur"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût unitaire"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats aux guichets automatiques"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes aux distributeurs automatiques"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions avec des actifs en bourse"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions d'actifs via P2P"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse d'hébergement"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du livre blanc"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrant"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres contacts"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller"])}
    },
    "Doc": {
      "Merchant": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ligne jusqu'à 255 caractères"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entier"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 chiffres"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ou 1"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre fractionnaire, précision à deux chiffres, séparateur de points"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entier"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chaîne jusqu'à 255 caractères, facultatif"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entier, facultatif"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chaîne de 32 caractères"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stocker le numéro de transaction ; \ndoit être unique pour chaque paiement ; \nen passant une chaîne"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un numéro unique sera généré automatiquement"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de magasin attribué après inscription"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de compte du magasin ; \nce compte détermine également dans quelle devise le paiement doit être effectué (dans la plupart des cas, l'utilisateur peut effectuer un paiement dans n'importe quelle devise, et les fonds seront automatiquement convertis dans la devise du destinataire)"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interdire au client de modifier le montant spécifié (1 - interdire, 0 - autoriser)"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant du bénéficiaire dans la devise du compte du bénéficiaire"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro du formulaire par lequel le paiement est accepté"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commentaire affiché au payeur comme objet du paiement"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paramètre optionnel supplémentaire qui sera transmis au script de résultat du paiement, ainsi qu'aux pages de retour"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL où le client reviendra après un paiement réussi ; \ns'il n'est pas spécifié, il est extrait des paramètres du magasin"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL où le client reviendra après une erreur ; \ns'il n'est pas spécifié, il est extrait des paramètres du magasin"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL où la notification des résultats de paiement sera envoyée ; \ns'il n'est pas spécifié, il est extrait des paramètres du magasin"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée de vie de la facture (en minutes), par défaut 60"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signer un message en utilisant la clé secrète du magasin"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ou 1"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - en cas de succès, 0 - sinon"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entier"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code d'erreur (0 s'il n'y a pas d'erreur)"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doubler"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["texte d'erreur (ou explication de l'état du paiement)"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ou 1"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un signe que le statut du paiement final n'est pas encore connu"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de transaction du magasin"])}
      }
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte personnel"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte professionnel"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronyme :"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lettres de l'alphabet latin sont acceptées, ainsi que le trait d'union et l'apostrophe."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lettres de l'alphabet latin sont acceptables, ainsi que le trait d'union, l'espace et l'apostrophe."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lettres de l'alphabet latin sont acceptées, ainsi que le trait d'union, les guillemets, les espaces, les chiffres et l'apostrophe."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Mon entreprise\" SARL"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettres latines (majuscules et minuscules), chiffres, symboles _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétez le mot de passe"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations utilisateur"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez une adresse e-mail ou un numéro de téléphone valide. Le numéro de téléphone doit être au format international, par exemple : +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes enregistré avec succès"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous connecter à votre compte personnel"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte sera vérifié par notre employé et nous vous informerons des résultats de son activation plus tard"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail avec un lien de confirmation d'inscription a été envoyé à votre adresse e-mail"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À votre adresse e-mail"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après la première connexion, il vous sera demandé de confirmer votre numéro de téléphone"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur non trouvé"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection des utilisateurs"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transaction"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres généraux"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement des soldes des comptes"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin de l'opération :"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au début de l'opération :"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres supplémentaires"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations dans la blockchain"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochaine tentative :"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection de la transaction"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de protection :"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'à"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de protection :"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du modèle"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'opération"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter l'opération"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de transaction"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro du formulaire de paiement"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes/Dépenses"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement des soldes des adresses"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'accréditation"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer l'argent à l'expéditeur"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération suspecte"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options d'actions :"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom :"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur non trouvé"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe :"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le mot de passe de confirmation"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter l'opération"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des opérations"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des comptes"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inconnue"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes actifs"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le nouvel actif"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème supplémentaire"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la blockchain"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée :"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'adresse"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrondissement"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoire urbain"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élément d'adresse supplémentaire"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maison"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de support technique"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un message"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez télécharger un ou plusieurs fichiers. Taille maximale du fichier - 10 Mo."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte du message"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de widget pour"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement en"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions en cas de succès"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions en cas d'échec"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération de code / lien de paiement"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'opération du magasin"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre supplémentaire"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de succès"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL d'échec"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de notification"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de retour du client après paiement réussi"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de retour du client après échec"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL d'envoi de notification de paiement"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixer le montant"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien de paiement"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de vie de la facture en minutes, par défaut 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de validité du lien"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne fixez pas le montant, le payeur pourra le saisir lui-même (adapté, par exemple, aux paiements caritatifs)"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le magasin"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voyez ceci, le formulaire n'a pas été reçu (mais vous ne devriez pas voir cela)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission supplémentaire"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptoactifs"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte - source de couverture"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de débit de commission"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème supplémentaire"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille actuelle du numéro"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JETON"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revêtement"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul de la couverture"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire:"])}
        },
        "Warning": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
        },
        "BurnEmission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brûlage d'actifs"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture de remboursement"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de gravure"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de calculer le montant"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un fichier"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce format de fichier n'est pas pris en charge. \nFormats acceptés :"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format inconnu"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier trop volumineux, taille maximale :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez ou faites glisser les fichiers ici pour les télécharger"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers téléchargés"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fichiers"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat :"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction :"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser le statut"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens utiles"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain :"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paramètres de sécurité"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation requise"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'autorisation doit être confirmée par une clé à usage unique"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'autorisation doit être confirmée par le mot magique"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'inscription doit être confirmée par un code SMS"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caractères"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur non valide"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ obligatoire"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez les champs"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour sélectionner les colonnes à afficher"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon après-midi"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne soirée"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne nuit"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiché :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par page"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])}
      },
      "Header": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le paiement"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer la commission de remboursement"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rembourser le paiement"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash dans la blockchain"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque AML"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À créditer"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous acceptez les nouvelles conditions, confirmez le crédit :"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si ces conditions ne vous conviennent pas, vous pouvez retourner les fonds sur le compte de l'expéditeur, moins les frais de remboursement :"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour un montant de"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["présente un risque AML"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par conséquent, la commission a été recalculée"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ainsi, le montant à créditer est"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune nouvelle entrée trouvée"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dos"])}
      }
    }
  }
}